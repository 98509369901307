// import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Components
import BetaBuild from './components/beta-build'

function HeneghansBetaBuild() {
  return (
    <Layout>
      <SiteNav fullLogo />
      <BetaBuild />
    </Layout>
  )
}

// HeneghansBetaBuild.propTypes = {
//   data: PropTypes.object,
// }

export default HeneghansBetaBuild
